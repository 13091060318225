@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

body {
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 0;
  margin: 0;
}

body {
  color: white;
  background-color: #282A42 !important;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}

code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}

code[class*='language-'] {
  padding: 0;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* a { cursor: pointer; color:white } */
/* a.link {
  color: #666CFF
} */
/* .close-btn {
  position: absolute;
  top: 0;
  right: 5px;
  background: none;
  border: none;
  font-size: 20px;
} */
/* button:focus {outline:0;} */
/* .table {
  color: white;
} */
.card,
.list-group-item {
  background: #30334E;
}

/* .btn-primary {
  background-color: #666CFF;
  border-color: #666CFF;
}
.btn-outline {
  color: #666CFF;
  background-color: transparent;
  border-color: #666CFF;
  cursor: pointer;
}
.btn-outline:hover {
  color: #666CFF;
}
.btn-outline-error {
  color: #FF4D49;
  background-color: transparent;
  border-color: #FF4D49;
  cursor: pointer;
}
.btn-outline-error:hover {
  color: #FF4D49;
  background-color: transparent;
  border-color: #FF4D49;
} */

/* input.MuiInputBase-input.MuiOutlinedInput-input[type=date][value=""] {
  color: transparent
}
div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused input {
  color: inherit !important
}
.login-panel {
  width: 480px;
  max-width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
.login-back {
  width: 100%;
  height: 100vh;
  object-fit: cover;
} */
/* .primary {
  color: #666CFF;
}
.error {
  
} */

/* .api-key-notice {
  border-radius: 8px;
  border: 1px solid var(--light-warning-main, #FDB528);
  padding: 7px 16px;
  color: var(--light-alert-warning-content, #FDB528);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
}
.sub-title {
  color: var(--dark-text-secondary, rgba(231, 227, 252, 0.68));
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15px;
}
.sub-title-small {
  color: var(--dark-text-secondary, rgba(231, 227, 252, 0.68));
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.15px;
}
.text-normal-italic {
  color: var(--dark-text-disabled, rgba(231, 227, 252, 0.38));
  font-size: 12px;
  font-style: italic;
  line-height: 20px;
  letter-spacing: 0.4px;
} */
