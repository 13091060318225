.ql-snow .ql-stroke {
    /* fill: white; */
    stroke: #acadc3;
}

.ql-fill {
    fill: #acadc3;
}

.ql-picker-label {
    color: #acadc3;
    ;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #acadc3;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    border-color: #66697f;
}

.ql-editor {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}

.ql-container.ql-snow {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    border-color: #66697f;
}

/* #66697f */